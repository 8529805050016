import * as validators from "@vuelidate/validators";

import { parsePhoneNumber } from 'awesome-phonenumber';

export class Validators
{
    constructor( ctx )
    {
        this.withI18nMessage = validators.createI18nMessage({ t: ctx.i18n.global.t.bind( ctx.i18n ) });

        this.customValidators =
        {
            linkedIn:
            {
                $validator  : ( value ) => { return value?.length > 0 ? /^(https?:\/\/|www\.|linkedin\.com\/)/.test( value ) : true },
                //$message    : ctx.i18n.global.t( 'customValidation.wrong-linkedin' ),
                $params     : { type: 'linkedIn' }
            },

            password:
            {
                $validator  : ( value ) => { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).+$/.test( value ) },
                //$message    : ctx.i18n.global.t( 'customValidation.wrong-password' ),
                $params     : { type: 'password' }
            },

            phone:
            {
                $validator  : ( value ) => { return value?.length > 0 ? parsePhoneNumber( value ).valid : true },
                //$message    : ctx.i18n.global.t( 'customValidation.wrong-phone-number' ),
                $params     : { type: 'phone' }
            },

            phoneWithPrefix: ( prefix ) => (
            {
                $validator  : ( value ) => { return prefix && value?.length > 0 ? parsePhoneNumber( value, { regionCode: prefix } ).valid : true },
                $message    : ctx.i18n.global.t( 'validations.phone' ),
                $params     : { type: 'phoneWithPrefix', prefix },
            }),

            parseServerErrors: ( error ) =>
            {
                if( error.hasOwnProperty('inputs') )
                {
                    let errors = {};

                    for( let input in error.inputs )
                    {
                        console.log( 'error.inputs[ input ]', error.inputs[ input ] );

                        errors[ input ] = ctx.i18n.global.t( `validations.${input}.${error.inputs[ input ].error}`, error.data );
                    }

                    return errors;
                }
                else if( error.hasOwnProperty('path') )
                {
                    return error.value.expected;
                }
            }
        }
    }

    //TODO validators.helpers
    get helpers()           { return validators.helpers }

    //VALIDATORS

    get alpha()             { return this.withI18nMessage( validators.alpha ) }
    get alphaNum()          { return this.withI18nMessage( validators.alphaNum ) }
    get decimal()           { return this.withI18nMessage( validators.decimal ) }
    get email()             { return this.withI18nMessage( validators.email ) }
    get phone()             { return this.withI18nMessage( this.customValidators.phone ) }
    get integer()           { return this.withI18nMessage( validators.integer ) }
    get ipAddress()         { return this.withI18nMessage( validators.ipAddress ) }
    get numeric()           { return this.withI18nMessage( validators.numeric ) }
    get required()          { return this.withI18nMessage( validators.required ) }
    get url()               { return this.withI18nMessage( validators.url ) }
    get linkedIn()          { return this.withI18nMessage( this.customValidators.linkedIn ) }
    get password()          { return this.withI18nMessage( this.customValidators.password ) }
    and( props )            { return this.withI18nMessage( validators.and( props ) ) }
    between( min, max )     { return this.withI18nMessage( validators.between( min, max ) ) }
    macAddress( separator ) { return this.withI18nMessage( validators.macAddress( separator ) ) }
    maxLength( max )        { return this.withI18nMessage( validators.maxLength( max ) ) }
    maxValue( max )         { return this.withI18nMessage( validators.maxValue( max ) ) }
    minLength( min )        { return this.withI18nMessage( validators.minLength( min ) ) }
    minValue( min )         { return this.withI18nMessage( validators.minValue( min ) ) }
    not( validator )        { return this.withI18nMessage( validators.not( validator ) ) }
    requiredIf( props )     { return this.withI18nMessage( validators.requiredIf( props ) ) }
    requiredUnless( props ) { return this.withI18nMessage( validators.requiredUnless( props ) ) }
    sameAs( equalTo )       { return this.withI18nMessage( validators.sameAs( equalTo ) ) }

    phoneWithPrefix ( prefix ) { return this.customValidators.phoneWithPrefix( prefix ) }

    parseServerErrors ( error ) { return this.customValidators.parseServerErrors( error ) }
}