import SwaggerService from "@/services/swagger.service";

import { Helpers } from "@/core/utils";
import { Filter }  from "@/core/filter";

const serviceRoute =
{
    role: 'iam'
}

const nameTransform =
{
    role           : 'AccountRole',

    countries           : 'location',
    areas               : 'location',
    economicAreas       : 'location',
    administrativeAreas : 'location',
    anyArea             : 'location',

    country        : 'location',

    employer       : 'account',
    recruiter      : 'account',
    accountManager : 'account',
    messageAccount : 'account',

    employerOrganization: 'organization',

    //custom  : 'CustomSuggest' => NEVIEM CO TO BOLO
};

const requestFilters =
{
    country         : { include: { type: ['country'] } },

    countries           : { include: { precision: 'countries' } },          // JE MOZNY AJ RESOLVE BEZ QUERY
    areas               : { include: { precision: 'areas' } },              // JE MOZNY AJ RESOLVE BEZ QUERY
    economicAreas       : { include: { precision: 'economic-areas' } },     // JE MOZNY AJ RESOLVE BEZ QUERY
    administrativeAreas : { include: { precision: 'administrative-areas' } },
    anyArea             : { include: { precision: 'any' } },

    employer        : { include: { type: ['employer'] } },
    recruiter       : { include: { type: ['recruiter'] } },
    accountManager  : { include: { type: ['admin'] } },
    messageAccount  : { include: { type: ['admin', 'recruiter', 'employer'] } },

    employerOrganization: { include: { type: ['employer'] } },
}

export default
{
    namespaced: true,
    state:
    {
        customFilter                    : { id: undefined, fields: [], data: undefined },

        //personOPT                       : [], TODO ASI ZMAZAT

        programmeOPT                    : [],
        programmeLocationOPT            : [],
        programmeTypeOPT                : [],
        feeTypeOPT                      : [],
        originTypeOPT                   : [],
        payerTypeOPT                    : [],
        agencyFeeCategoryOPT            : [],
        recruiterFeeCategoryOPT         : [],
        bonusTypeOPT                    : [],
        tierReleaseTypeOPT              : [],
        nationalityOPT                  : [],
        engagementTypeOPT               : [],

        personTitleOPT                  : [],
        experienceOPT                   : [],
        categoryOPT                     : [],
        categoryHierarchyOPT            : [],
        skillOPT                        : [],
        tagOPT                          : [],

        organizationOPT                 : [],
        jobOPT                          : [],
        applicationOPT                  : [], // TODO
        placementOPT                    : [], // TODO
        contractOPT                     : [], // TODO

        accountOPT                      : [],
        accountManagerOPT               : [],
        employerOPT                     : [],
        recruiterOPT                    : [],

        jobStatusOPT                    : [],
        jobStageStatusOPT               : [],
        invitationStatusOPT             : [],
        applicationStatusOPT            : [],
        placementStatusOPT              : [],
        contractStatusOPT               : [],

        messageAccountOPT               : [],
        roleOPT                         : [],
        currencyOPT                     : [],
        contractTypeOPT                 : [],
        durationOPT                     : [],
        hoursRateOPT                    : [],
        timeRateOPT                     : [],
        mailTimeRateOPT                 : [],
        genderOPT                       : [],

        locationOPT                     : [],
        countryOPT                      : [],
        countryCodeOPT                  : [],

        countriesOPT                    : [],
        areasOPT                        : [],
        economicAreasOPT                : [],
        administrativeAreasOPT          : [],
        anyAreaOPT                      : [],

        languageOPT                     : [],

        industryOPT                     : [],
        organizationSizeOPT             : [],
        organizationPositionLevelOPT    : [],
        organizationPositionFunctionOPT : [],

        employerOrganizationOPT         : [],

        insightsJobsOPT                 : [],

        applicationRejectionReasonOPT   : [],
        applicationWithdrawalReasonOPT  : [],
        rejectionReasonOPT              : [],

        onHoldReasonOPT                 : [],
        closeReasonOPT                  : [],

        emailPreferencesOPT             : [],

        phonePrefixesOPT                :
        [
            { id: "AF", label: "+93" },
            { id: "AL", label: "+355" },
            { id: "DZ", label: "+213" },
            { id: "AD", label: "+376" },
            { id: "AO", label: "+244" },
            { id: "AR", label: "+54" },
            { id: "AM", label: "+374" },
            { id: "AU", label: "+61" },
            { id: "AT", label: "+43" },
            { id: "AZ", label: "+994" },
            { id: "BH", label: "+973" },
            { id: "BD", label: "+880" },
            { id: "BY", label: "+375" },
            { id: "BE", label: "+32" },
            { id: "BZ", label: "+501" },
            { id: "BJ", label: "+229" },
            { id: "BO", label: "+591" },
            { id: "BA", label: "+387" },
            { id: "BR", label: "+55" },
            { id: "BG", label: "+359" },
            { id: "CA", label: "+1" },
            { id: "CL", label: "+56" },
            { id: "CN", label: "+86" },
            { id: "CO", label: "+57" },
            { id: "HR", label: "+385" },
            { id: "CY", label: "+357" },
            { id: "CZ", label: "+420" },
            { id: "DK", label: "+45" },
            { id: "EG", label: "+20" },
            { id: "FI", label: "+358" },
            { id: "FR", label: "+33" },
            { id: "DE", label: "+49" },
            { id: "GR", label: "+30" },
            { id: "HU", label: "+36" },
            { id: "IN", label: "+91" },
            { id: "ID", label: "+62" },
            { id: "IE", label: "+353" },
            { id: "IL", label: "+972" },
            { id: "IT", label: "+39" },
            { id: "JP", label: "+81" },
            { id: "KZ", label: "+7" },
            { id: "KE", label: "+254" },
            { id: "KR", label: "+82" },
            { id: "KW", label: "+965" },
            { id: "LV", label: "+371" },
            { id: "LT", label: "+370" },
            { id: "LU", label: "+352" },
            { id: "MY", label: "+60" },
            { id: "MX", label: "+52" },
            { id: "MA", label: "+212" },
            { id: "NL", label: "+31" },
            { id: "NZ", label: "+64" },
            { id: "NG", label: "+234" },
            { id: "NO", label: "+47" },
            { id: "PK", label: "+92" },
            { id: "PE", label: "+51" },
            { id: "PH", label: "+63" },
            { id: "PL", label: "+48" },
            { id: "PT", label: "+351" },
            { id: "RO", label: "+40" },
            { id: "RU", label: "+7" },
            { id: "SA", label: "+966" },
            { id: "RS", label: "+381" },
            { id: "SG", label: "+65" },
            { id: "SK", label: "+421" },
            { id: "SI", label: "+386" },
            { id: "ZA", label: "+27" },
            { id: "ES", label: "+34" },
            { id: "SE", label: "+46" },
            { id: "CH", label: "+41" },
            { id: "TH", label: "+66" },
            { id: "TR", label: "+90" },
            { id: "UA", label: "+380" },
            { id: "AE", label: "+971" },
            { id: "GB", label: "+44" },
            { id: "US", label: "+1" },
            { id: "VN", label: "+84" }
        ]
    },
    mutations:
    {
        customFilter( state, { id, fields, data })
        {
            state.customFilter.id     = id;
            state.customFilter.fields = fields;
            state.customFilter.data   = data;

            for( let field of fields )
            {
                if( !state[field + 'OPT'] )
                {
                    state[field + 'OPT'] = []
                }
            }
        },

        resetState( state )
        {
            for( const key in state )
            {
                if( key === 'phonePrefixesOPT' )
                {
                    continue;
                }

                if( Array.isArray( state[ key ] ) )
                {
                    state[key] = [];
                }
                else if( typeof state[ key ] === 'object' && state[ key ] !== null )
                {
                    const emptyObject = {};

                    for( const prop in state[ key ] )
                    {
                        if( Array.isArray( state[ key ][ prop ] ) )
                        {
                            emptyObject[ prop ] = [];
                        }
                        else
                        {
                            emptyObject[ prop ] = undefined;
                        }
                    }

                    state[ key ] = emptyObject;
                }
                else
                {
                    state[ key ] = undefined;
                }
            }
        }
    },
    actions:
    {
        //THIS METHOD SHOULD RESOLVE ONLY STATIC OPTIONS => NO FILTER USED => RESOLVER FOR PRELOADING OPTIONS
        async resolve({ commit, dispatch, state }, options )
        {
            //console.log('SELECT SUGGEST RESOLVE =>', { options });

            let $_promises = [], $_result = {};

            for( let option of options )
            {
                $_promises.push( ( async () =>
                {
                    let isCustomFilter = state.customFilter.fields.includes( option );

                    let $_state = state[option + 'OPT'];

                    if( !$_state ){ throw { code: 404, message: `Type ${option} not found` } }

                    if( !$_state.length ) //TODO MOZNO NAPICU
                    {
                        let requestType = isCustomFilter ? 'custom' : ( nameTransform?.[option] || option );

                        let requestFilter = {};

                        if( requestFilters[option] )
                        {
                            requestFilter = { query: requestFilters[option].include };
                        }

                        if( requestType === 'custom' )
                        {
                            requestFilter = { query: { property: [ option ] } };
                        }

                        if( serviceRoute[option] )
                        {
                            try
                            {
                                for( let $_option of await SwaggerService.iam_select_options( requestType, requestFilter ) )
                                {
                                    if( !$_state.find( o => o.id === $_option.id ) )
                                    {
                                        $_state.push( $_option );
                                    }
                                }
                            }
                            catch( e )
                            {
                                console.warn( 'Failed to fetch: iam_select_options =>', e );
                            }
                        }
                        else
                        {
                            try
                            {
                                for( let $_option of await SwaggerService.select_options( requestType, requestFilter ) )
                                {
                                    if( !$_state.find( o => o.id === $_option.id ) )
                                    {
                                        $_state.push( $_option );
                                    }
                                }
                            }
                            catch( e )
                            {
                                console.warn( 'Failed to fetch: select_options =>', e );
                            }
                        }
                    }

                    $_result[option] = JSON.parse( JSON.stringify( $_state ) );

                })());
            }

            await Promise.all( $_promises );

            return $_result;
        },

        //THIS METHOD SHOULD RESOLVE DYNAMIC FILTERED OPTIONS => FILTER USED => RESOLVER FOR SEARCHING OPTIONS
        async options({ commit, dispatch, state }, { type, filter })
        {
            //console.log('SELECT SUGGEST OPTIONS =>', JSON.parse(JSON.stringify({ type, filter })));

            let isCustomFilter = state.customFilter.fields.includes( type );

            let requestType = isCustomFilter ? 'custom' : ( nameTransform?.[type] || type );

            let $_state = state[type + 'OPT'], $_options = [], $_fetchOptions;

            if( !$_state ){ throw { code: 404, message: `Type ${type} not found` } }

            if( $_state.length )
            {
                if( filter?.include?.ids )
                {
                    for( let id of filter.include.ids )
                    {
                        let $_option = $_state.find( item => item.id === id );

                        if( $_option )
                        {
                            filter.include.ids = filter.include.ids.filter( item => item !== id );

                            $_options.push( $_option );
                        }
                    }
                }
            }

            if( filter?.include )
            {
                if( filter?.include?.ids )
                {
                    filter.include.ids = filter.include.ids.filter( Boolean ).map( id => isNaN( id ) ? encodeURIComponent( id ) : id );
                }

                if( !filter?.include?.ids?.length )
                {
                    delete filter.include.ids;
                }
            }

            if( requestFilters[type] )
            {
                //Object.assign( filter?.include || {}, requestFilters[type].include );

                Helpers.ObjectMerge( filter?.include || {}, requestFilters[type].include );
            }

            //FETCH REQUEST NA OPTIONY IBA KED JE SEARCH QUERY ALEBO IDCKA KTORE NIESU V STORE
            if( filter?.forceFetch || filter?.include?.query?.length || filter?.include?.ids?.length || filter?.include?.parentID )
            {
                if( serviceRoute[ type ] )
                {
                    try
                    {
                        $_fetchOptions = await SwaggerService.iam_select_options( requestType, { query: filter?.include || {} });
                    }
                    catch( e )
                    {
                        console.warn( 'Failed to fetch: iam_select_options =>', e );
                    }
                }
                else
                {
                    try
                    {
                        $_fetchOptions = await SwaggerService.select_options( requestType, { query: filter?.include || {} });
                    }
                    catch( e )
                    {
                        console.warn( 'Failed to fetch: select_options =>', e );
                    }
                }

                if( $_fetchOptions )
                {
                    for( let option of $_fetchOptions )
                    {
                        ( !$_state.find( item => item.id === option.id ) )   && $_state.push( option );
                        ( !$_options.find( item => item.id === option.id ) ) && $_options.push( option );
                    }
                }
            }

            return JSON.parse( JSON.stringify( $_options ) );
        },

        //TODO: THIS METHOD SHOULD RESOLVE DYNAMIC FILTERED OPTIONS => FILTER USED => RESOLVER FOR SEARCHING OPTIONS
        async customFilter({ commit, dispatch, state }, { currentAccount })
        {
            try
            {
                let custom_filters = await SwaggerService.custom_filters();

                if( custom_filters.length )
                {
                    Filter.extendFilterObject( custom_filters );
                }

                commit( 'customFilter', { id: currentAccount.organization.id, fields: custom_filters.map( f => f.id ), data: custom_filters });
            }
            catch( e )
            {
                console.warn( 'Failed to fetch: customFilter =>', e );
            }
        },

        resetState({ commit })
        {
            commit('resetState');
        }
    },
    getters:
    {
        customFilter( state )
        {
            return state.customFilter;
        }
    }
};
