import SwaggerService from "@/services/swagger.service";

import * as Sentry from "@sentry/vue";

import { Helpers } from "@/core/utils";

import { computed, reactive } from "vue";

export default
{
    namespaced : true,
    state      : { isLoggingIn: false, userDetail: null },
    mutations  :
    {
        isLoggingIn( state, payload )
        {
            state.isLoggingIn = payload;
        },

        set( state, user )
        {
            if( user )
            {
                Sentry.setUser({ id: user.id, email: user.email, username: user.name })

                Sentry.startSession();
            }
            else
            {
                Sentry.setUser( null );

                Sentry.endSession();
            }

            state.userDetail = ( user === false ? null : user );
        }
    },
    actions:
    {
        async get({ commit, state, dispatch }, options, force = false )
        {
            try
            {
                if( ( !state.userDetail && options.requiresAuth ) || force )
                {
                    let $_userDetail = await SwaggerService.get_me().catch( e =>
                    {
                        document.cookie = `redirectAfterLogin=${encodeURIComponent(window.location.href)}; path=/; domain=.ramp.global; Secure`;

                        return dispatch('logout')
                    });

                    Helpers.Storage( 'local', 'set', 'X-UserID', $_userDetail.id );

                    let $_userInvitations = await SwaggerService.iam_get_my_invitations();
                    let $_organizationID  = Helpers.Storage( undefined, 'get', 'X-OrganizationID' );

                    if( $_organizationID || ( $_userDetail.accounts.length === 1 && !$_userInvitations.pending.length && !$_userInvitations.requested.length ) )
                    {
                        const isAccountOrganization = $_userDetail.accounts.find( account => account.organization.id === $_organizationID );

                        if( isAccountOrganization )
                        {
                            $_userDetail.currentAccount = isAccountOrganization;
                        }
                        else if( $_userDetail.accounts.length === 1 )
                        {
                            $_userDetail.currentAccount = $_userDetail.accounts[0];
                        }

                        if( $_userDetail.currentAccount )
                        {
                            Helpers.Storage(undefined, 'set', 'X-AccountID', $_userDetail.currentAccount.id );
                            Helpers.Storage(undefined, 'set', 'X-OrganizationID', $_userDetail.currentAccount.organization.id );

                            dispatch( 'updateAccountScore', { userDetail: $_userDetail } );
                        }
                        else
                        {
                            Helpers.Storage(undefined, 'remove', 'X-AccountID' );
                            Helpers.Storage(undefined, 'remove', 'X-OrganizationID' );
                        }
                    }

                    commit( 'set', $_userDetail );

                    return true;
                }

                return false;
            }
            catch( e )
            {
                console.error( 'GET ME ERROR =>', e );

                return false;
            }
        },

        async selectAccount({ commit, state, dispatch }, { account, reload = true, reloadType = 'redirectAfterAccountSelected', handleLoggingIn = true } )
        {
            handleLoggingIn && commit( 'isLoggingIn', true );

            Helpers.Storage( 'session', 'remove', 'session-tracker-id' );
            Helpers.Storage( 'session', 'remove', 'session-tracker-expires' );

            dispatch( 'ramp/base/select/resetState', null, { root: true });

            Helpers.Storage( undefined, 'set', 'X-AccountID', account.id );
            Helpers.Storage( undefined, 'set', 'X-OrganizationID', account.organization.id );

            let $_userDetail = await SwaggerService.get_me().catch( e => dispatch( 'logout' ) );

            $_userDetail.currentAccount = account;

            dispatch( 'updateAccountScore', { userDetail: $_userDetail } );

            commit( 'set', $_userDetail );

            handleLoggingIn && commit( 'isLoggingIn', false );

            if( reload )
            {
                if( reloadType === 'redirectAfterAccountSelected' )
                {
                    const redirectUrl = sessionStorage.getItem("redirectAfterAccountSelected") || "/";

                    sessionStorage.removeItem('redirectAfterAccountSelected');

                    location.href = redirectUrl;
                }
                else if( reloadType === 'currentURL' )
                {
                    sessionStorage.setItem('selectAccountURL', location.href);

                    //location.reload();
                }
            }
        },

        async updateAccountScore({ commit, state }, { userDetail })
        {
            let [ user, account, company ] = await Promise.all(
            [
                SwaggerService.get_user( userDetail.id ),
                SwaggerService.get_account( userDetail.currentAccount.id, { query: { raw: true } } ),
                SwaggerService.get_organization( userDetail.currentAccount.organization.id, { query: { raw: true } } )
            ]);

            userDetail.name  = user.name;
            userDetail.email = user.email;

            userDetail.currentAccount              = Object.assign( account, userDetail.currentAccount );
            userDetail.currentAccount.organization = Object.assign( company, userDetail.currentAccount.organization );

            userDetail.currentAccount.emailPreferences = account.emailPreferences;

            userDetail.currentAccount.stats = reactive(
            {
                personal : userDetail?.currentAccount?.completeness || 0,
                company  : userDetail?.currentAccount?.organization?.completeness || 0
            });

            userDetail.currentAccount.totalStats = computed(() =>
            {
                const scoreValues = Object.values( userDetail.currentAccount.stats );
                const totalScore  = scoreValues.reduce( ( total, score ) => total + score, 0 );
                const maxScore    = scoreValues.length * 100;

                return Math.round(( totalScore / maxScore ) * 100 );
            });
        },

        async updateMeta({ commit, state }, meta )
        {
            if( state?.userDetail?.currentAccount )
            {
                if( !state.userDetail.currentAccount.hasOwnProperty('metas') )
                {
                    state.userDetail.currentAccount.metas = {};
                }

                for( let metaKey of Object.keys( meta ) )
                {
                    if( state.userDetail.currentAccount.metas.hasOwnProperty( metaKey ) )
                    {
                        if( state.userDetail.currentAccount.metas[ metaKey ] !== meta[ metaKey ] )
                        {
                            if( typeof meta[ metaKey ] === "function" )
                            {
                                state.userDetail.currentAccount.metas[ metaKey ] = meta[ metaKey ] instanceof Promise ? await meta[ metaKey ]() : meta[ metaKey ]();
                            }
                            else
                            {
                                state.userDetail.currentAccount.metas[ metaKey ] = meta[ metaKey ];
                            }
                        }
                    }
                    else
                    {
                        if( typeof meta[ metaKey ] === "function" )
                        {
                            state.userDetail.currentAccount.metas[ metaKey ] = meta[ metaKey ] instanceof Promise ? await meta[ metaKey ]() : meta[ metaKey ]();
                        }
                        else
                        {
                            state.userDetail.currentAccount.metas[ metaKey ] = meta[ metaKey ];
                        }
                    }
                }

                commit( 'set', state.userDetail );
            }
        },

        async login({ commit })
        {
            await SwaggerService.login();
        },

        async loginWithToken({ commit, state, dispatch }, token )
        {
            let $_userDetail = await SwaggerService.loginWithToken( token );

            if( $_userDetail )
            {
                commit( 'set', null );

                dispatch( 'ramp/base/select/resetState', null, { root: true });

                Helpers.Storage( undefined, 'remove', 'X-UserID' );
                Helpers.Storage( undefined, 'remove', 'X-AccountID' );
                Helpers.Storage( undefined, 'remove', 'X-OrganizationID' );

                Helpers.Storage( undefined, 'set', 'X-AccountID', $_userDetail.accountID );
                Helpers.Storage( undefined, 'set', 'X-OrganizationID', $_userDetail.organizationID );

                return await dispatch( 'get', { requiresAuth: true } );
            }
        },

        async loginAs({ commit, state, dispatch }, user )
        {
            commit( 'isLoggingIn', true );

            let $_userToken = await SwaggerService.loginAs( user.accountID );

            await dispatch( 'loginWithToken', { token: $_userToken.replace('?authToken=', '') } );

            commit( 'isLoggingIn', false );
        },

        async logout({ commit, dispatch })
        {
            Helpers.Storage(undefined, 'remove', 'X-UserID' );
            Helpers.Storage(undefined, 'remove', 'X-AccountID' );
            Helpers.Storage(undefined, 'remove', 'X-OrganizationID' );

            commit( 'set', null );

            await SwaggerService.logout();

            location.href = import.meta.env.VITE_APP_AUTH_URL + 'login';
        }
    },
    getters:
    {
        detail( state )
        {
            return state.userDetail;
        },

        programmeIDs( state )
        {
            return state.userDetail?.currentAccount?.programmeIDs;
        },

        roles( state )
        {
            return state?.userDetail?.currentAccount?.roles;
        },

        metas: state => () =>
        {
            return state?.userDetail?.currentAccount?.metas;
        },

        permissions: state => () =>
        {
            if( !state.userDetail || !state.userDetail.currentAccount )
            {
                return [];
            }

            let permissionFlag = state.userDetail.currentAccount.organization.flag;

            if( permissionFlag === 'agency' ) //FALLBACK FOR AGENCY
            {
                permissionFlag = 'recruiter';
            }

            return [ ...new Set( state.userDetail.currentAccount.roles.reduce( ( permissions, role ) => permissions.concat( role.permissions ), [ permissionFlag ] ) ) ];
        },

        isLoggingIn( state )
        {
            return state.isLoggingIn;
        },

        isLoggedIn( state )
        {
            return state.userDetail !== null;
        },

        isEulaAccepted( state )
        {
            if( state.userDetail.currentAccount.organization.flag === 'agency' )
            {
                return state.userDetail.eulaAccepted && state.userDetail.currentAccount.organizationTerms;
            }
            else
            {
                return state.userDetail.eulaAccepted;
            }
        }
    }
};
