import { Helpers } from "@/core/utils";

export class Permission
{
    constructor( ctx )
    {
        this.store = ctx.store;
    }

    has( scope )
    {
        const $_userPermissions = this.store.getters['auth/user/permissions']();

        if( Array.isArray( scope ) )
        {
            for( let rule of scope )
            {
                if( $_userPermissions.includes( rule ) )
                {
                    return true;
                }
            }

            return false;
        }
        else
        {
            return $_userPermissions.includes( scope );
        }
    }

    flag()
    {
        let permissionFlag;

        if( this.store.getters['auth/user/detail'].currentAccount.organization.flag === 'admin' && Helpers.Storage( undefined, 'get', 'permission' ) )
        {
            permissionFlag = Helpers.Storage( undefined, 'get', 'permission' );
        }
        else
        {
            permissionFlag = this.store.getters['auth/user/detail'].currentAccount.organization.flag;
        }

        if( permissionFlag === 'agency' ) { permissionFlag = 'recruiter'; }
        if( permissionFlag === 'admin' )  { permissionFlag = 'employer'; }

        return permissionFlag;
    }

    isRampAccount()
    {
        return this?.store?.getters?.['auth/user/detail']?.currentAccount?.organization?.id === '34992bc232b218f0f077d90f';
    }

    getRampAccount()
    {
        if( this.store.getters['auth/user/detail'].currentAccount.organization.flag === 'admin' )
        {
            return '34992bc232b218f0f077d90f';
        }
        else
        {
            throw new Error('You do not have permission to access this data.');
        }
    }

    getUserHeaders()
    {
        return (
        {
            'X-OrganizationID': this.store.getters['auth/user/detail'].currentAccount.organization.id,
            'X-AccountID'     : this.store.getters['auth/user/detail'].currentAccount.id
        })
    }

    isVisible( type, source, data )
    {
        if( false && import.meta.env.MODE === 'production' &&  [
            //'login-as',
            'only-admin',
            'job-create',
            'job-edit',
            'job-duplicate',
            'job-delete',
            'job-status-open',
            'job-status-pause',
            'job-status-close',
            'job-invitation-create',
            'job-invitation-progress',
            'job-invitation-withdraw',
            'job-invitation-negotiate',
            'job-engagement-disengage',
            'job-engagement-fee-edit',
            'job-engagement-cv-slots-edit',
            'job-briefing-create',
            'job-briefing-status-edit',
            'job-briefing-edit',
            'application-create',
            'application-edit',
            'application-duplicate',
            'application-withdraw',
            'application-reject',
            'application-dropout',
            'application-review',
            'application-interview',
            'application-offer',
            'application-offer-edit',
            'application-offer-confirm',
            'application-briefing-create',
            'application-briefing-status-edit',
            'application-briefing-edit',
            'application-approve-start-date',
            'application-placement',
            'contract-create',
            'contract-edit',
            'contract-duplicate',
            'contract-delete',
            'contract-extend',
            'contract-terminate',
            'contract-dropout',
            'contract-timesheet-add',
            'contract-timesheet-edit',
            'contract-timesheet-delete',
            'placement-create',
            'placement-edit',
            'placement-duplicate',
            'placement-delete',
            'placement-dropout',
            'message-create',
            'message-reply',
            'message-delete',
            'message-employer',
            'message-recruiter',
            'message-account-manager',
            'livechat-reply',
            'livechat-join',
            'livechat-reply-to-message',
            'note-create',
            'note-progress',
            'note-delete',
            //'account-edit',
            //'agency-edit',
            'agency-invite',
            'agency-exclude',
            'agency-exclude-remove',
            'agency-onboarding-invitation-create',
            'agency-onboarding-invitation-accept',
            'agency-onboarding-invitation-decline',
            'application-interview-date-pick',
            'program-edit',
            'agency-tag',
            'agency-update-subscription',
            'job-invitation-reminder'
        ].includes( type ) )
        {
            return false;
        }
        
        if( type === 'login-as' || type === 'only-admin' )
        {
            if( source === 'global' ) { return this.has(['admin']); }
        }

        if( type ==='program-edit' )
        {
            if( source === 'program' )
            {
                return !this.isRampAccount() && this.has([ 'admin' ]);
            }
        }

        if( type ==='account-edit' )
        {
            if( source === 'account-list' || source === 'account' )
            {
                return this.has([ 'admin' ]) || ( this.has([ 'recruiter' ]) && ( ( data.id === this.store.state.auth.user.userDetail.currentAccount.id ) || ( data.id === this.store.state.auth.user.userDetail.id ) ) );
            }
        }

        if( type ==='agency-edit' )
        {
            if( source === 'agency-list' || source === 'agency' )
            {
                return this.has([ 'admin' ]) || ( this.has([ 'recruiter' ]) && data.id === this.store.state.auth.user.userDetail.currentAccount.organization.id );
            }
        }

        if( type === 'agency-tag' )
        {
            if( source === 'agency-list' || source === 'agency' )
            {
                return !this.isRampAccount() && this.has([ 'admin', 'employer' ]);
            }
        }

        if( type === 'agency-invite' )
        {
            if( source === 'agency-list' || source === 'agency' || source === 'global' )
            {
                return !this.isRampAccount() && this.has([ 'admin', 'employer' ]);
            }
        }

        if( type === 'agency-exclude' )
        {
            if( source === 'agency-list' || source === 'agency' )
            {
                return !this.isRampAccount() && this.has([ 'admin', 'employer' ]) && !data.company.value?.blacklist?.agencies?.find( a => a.id === data.agency.id );
            }
        }

        if( type === 'agency-exclude-remove' )
        {
            if( source === 'agency-list' || source === 'agency' )
            {
                return !this.isRampAccount() && this.has([ 'admin', 'employer' ]) && data.company.value?.blacklist?.agencies?.find( a => a.id === data.agency.id );
            }
        }

        if( type === 'agency-onboarding-invitation-create' )
        {
            if( source === 'agency-list' || source === 'agency' || source === 'global' )
            {
                return !this.isRampAccount() && this.has(['admin']);
            }
        }

        if( type === 'agency-onboarding-invitation-accept' )
        {
            if( source === 'agency-list' || source === 'agency' || source === 'global' )
            {
                return !this.isRampAccount() && this.has(['recruiter']) && data.onboarding.status === 'pending';
            }
        }

        if( type === 'agency-onboarding-invitation-decline' )
        {
            if( source === 'agency-list' || source === 'agency' || source === 'global' )
            {
                return !this.isRampAccount() && this.has(['recruiter']) && data.onboarding.status === 'pending';
            }
        }

        if( type === 'agency-update-subscription' )
        {
            if( source === 'agency-list' || source === 'agency' )
            {
                return this.has([ 'admin' ]);
            }
        }



        if( type === 'job-create' )
        {
            if( source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']); }
        }

        if( type === 'job-edit' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) }
        }

        if( type === 'job-duplicate' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) }
        }

        if( type === 'job-delete' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) }
        }

        if( type === 'job-status-open' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) && data.status !== 'open'; }
        }

        if( type === 'job-status-pause' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) && data.status !== 'paused'; }
        }

        if( type === 'job-status-close' )
        {
            if( source === 'job' || source === 'job-list' ) { return !this.isRampAccount() && this.has(['admin','employer']) && data.status !== 'closed'; }
        }

        if( type === 'job-invitation-create' )
        {
            if( source === 'job' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && data.status === 'open' /*&& data?.$actions?.hasFreeEngagementSlots*/ && data?.positions?.open > 0;
            }
            else if( source === 'job-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && data.status === 'open' /*&& data?.$actions?.hasFreeEngagementSlots*/ && data?.summary?.positions?.open > 0;
            }
        }

        if( type === 'job-invitation-progress' )
        {
            if( source === 'invitation-list' )
            {
                return this.has(['recruiter']) && data.status === 'pending' && data?.$actions?.acceptable;
            }
            else if( source === 'job' )
            {
                return this.has(['recruiter']) && data.status === 'open' && data.pendingInvitation && data.$actions.hasFreeEngagementSlots && data.positions.open > 0;
            }
        }

        if( type === 'job-invitation-negotiate' )
        {
            if( source === 'invitation-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && data.status === 'negotiate'; //TODO chyba job status handle
            }
            else if( source === 'job' )
            {
                //TODO
            }
        }

        if( type === 'job-invitation-withdraw' )
        {
            if( source === 'invitation-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['pending','negotiate'].includes( data.status );
            }
        }

        if( type === 'job-invitation-reminder' )
        {
            if( source === 'invitation-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['pending'].includes( data.status );
            }
        }



        if( type === 'job-engagement-disengage' )
        {
            if( source === 'job' || source === 'job-list' || source === 'engagement-list' )
            {
                if( this.has(['recruiter']) )
                {
                    return !this.isRampAccount() && ['open','paused'].includes( data.status ) && data?.$actions?.disengageID;
                }
                else if( this.has(['admin', 'employer']) && data.hasOwnProperty('engagement') )
                {
                    return !this.isRampAccount() && ['open','paused'].includes( data.status ) && data?.engagement?.status !== 'disengaged';
                }
            }
        }

        if( type === 'job-engagement-fee-edit' )
        {
            if( source === 'engagement-list' )
            {
                return !this.isRampAccount() && this.has(['admin']) && ['open','paused'].includes( data.job.status ) && data.engagement.status === 'active';
            }
        }

        if( type === 'job-engagement-cv-slots-edit' )
        {
            if( source === 'engagement-list' )
            {
                if( data.hasOwnProperty('engagements') )
                {
                    return !this.isRampAccount() && this.has(['admin']) && ['open','paused'].includes( data.job.status ) && !data.engagements.find( e => e.status !== 'active' );
                }
                else
                {
                    return !this.isRampAccount() && this.has(['admin']) && ['open','paused'].includes( data.job.status ) && data.engagement.status === 'active';
                }
            }
        }

        if( type === 'job-briefing-create' )
        {
            if( source === 'job' || source === 'job-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status );
            }
        }

        if( type === 'job-briefing-status-edit' )
        {
            if( source === 'job' || source === 'job-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status ) && data.briefing.status !== data.newStatus;
            }
        }

        if( type === 'job-briefing-edit' )
        {
            if( source === 'job' || source === 'job-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status );
            }
        }



        if( type === 'application-create' )
        {
            if( source === 'job' || source === 'job-list' )
            {
                return this.has(['recruiter']) && data.job.status === 'open' && data?.job?.$actions?.submitApplication;
            }
        }

        if( type === 'application-edit' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return this.has(['recruiter']) && !['placed', 'hired'].includes( data.application.status );
            }
        }

        if( type === 'application-duplicate' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return this.has(['recruiter']);
            }
        }

        if( type === 'application-withdraw' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['recruiter']) && ['open','paused'].includes( data.job.status ) && ["submitted","reviewed","interviewing","under-offer"].includes( data.application.status );
            }
        }

        if( type === 'application-reject' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status ) && ["submitted","reviewed","interviewing","under-offer"].includes( data.application.status );
            }
        }

        if( type === 'application-dropout' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ["placed","hired"].includes( data.application.status );
            }
        }

        if( type === 'application-review' || type === 'application-interview' || type === 'application-offer' )
        {
            if( source === 'job-engagement-applications' || source === 'application' || source === 'application-list' )
            {
                //this.has(['recruiter', 'employer']) => stara podmienka neviem ktora je spravna
                if( !this.isRampAccount() && this.has(['admin', 'employer']) && ["under-offer"].includes( data.application.status ) && !data.application.hasOwnProperty('offer') )
                {
                    if( type === 'application-offer' )
                    {
                        return true;
                    }
                }

                if( !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status ) )
                {
                    if( type === 'application-review' )
                    {
                        return [ "submitted" ].includes( data.application.status );
                    }
                    if( type === 'application-interview' )
                    {
                        return [ "reviewed" ].includes( data.application.status );
                    }
                    if( type === 'application-offer' )
                    {
                        return [ "interviewing" ].includes( data.application.status );
                    }
                }
            }
        }

        if( type === 'application-interview-date-pick' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer','recruiter']) && ['open','paused'].includes( data.application.job.status ) && ["interviewing"].includes( data.application.status ) && data.application?.interview?.dates?.length && !data.application?.interview?.dates?.find( d => d.status === 'accepted' );
            }
        }

        if( type === 'application-offer-edit' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return this.has(['admin','recruiter','employer']) && ['open','paused'].includes( data.application.job.status ) && ["under-offer"].includes( data.application.status ) && ( data.application.hasOwnProperty('offer') && data.application.offer?.proposer?.organization?.id === this.store.state.auth.user.userDetail.currentAccount.organization.id );
            }
        }

        if( type === 'application-offer-confirm' )
        {
            if( source === 'job-engagement-applications' || source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer','recruiter']) && ['open','paused'].includes( data.job.status ) && data.application.status === 'under-offer' && data.application.hasOwnProperty('offer') && data.application?.offer?.proposer?.organization?.id !== this.store.state.auth.user.userDetail.currentAccount.organization.id;
            }
        }

        if( type === 'application-approve-start-date' )
        {
            if( source === 'job-engagement-applications' || source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ["placed"].includes( data.application.status );
            }
        }

        if( type === 'application-placement' )
        {
            if( source === 'application' || source === 'application' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.job.status ) && !["placed","hired"].includes( data.application.status ); //"under-offer" was removed from status handle => why?
            }
        }

        if( type === 'application-briefing-create' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.application.job.status ) && data.application.stages.includes( 'interviewing' );
            }
        }

        if( type === 'application-briefing-status-edit' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.application.job.status ) && data.briefing.status !== data.newStatus;
            }
        }

        if( type === 'application-briefing-edit' )
        {
            if( source === 'application' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && ['open','paused'].includes( data.application.job.status );
            }
        }



        if( type === 'contract-create' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-edit' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-duplicate' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-delete' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-extend' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-terminate' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-dropout' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-timesheet-add' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-timesheet-edit' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'contract-timesheet-delete' )
        {
            if( source === 'contract-list' || source === 'contract' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }



        if( type === 'placement-create' )
        {
            if( source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'placement-edit' )
        {
            if( source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'placement-duplicate' )
        {
            if( source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'placement-delete' )
        {
            if( source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }

        if( type === 'placement-dropout' )
        {
            if( source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
        }



        if( type === 'message-create' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount();
            }
        }

        if( type === 'message-reply' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount() && data?.sender?.id !== this.store.state.auth.user.userDetail.currentAccount.id;
            }
        }

        if( type === 'message-delete' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount();
            }
        }

        if( type === 'message-employer' )
        {
            if( source === 'job' || source === 'job-list' || source === 'application-list' )
            {
                return !this.isRampAccount() && this.has(['admin','recruiter']);
            }
            else if( source === 'contract-list' || source === 'contract' || source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','recruiter']) && data?.employer?.length;
            }
        }

        if( type === 'message-recruiter' )
        {
            if( source === 'job-list' || source === 'application-list' || source === 'engagement-list' || source === 'invitation-list' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']);
            }
            else if( source === 'contract-list' || source === 'contract' || source === 'placement-list' || source === 'placement' )
            {
                return !this.isRampAccount() && this.has(['admin','employer']) && data?.recruiter?.id;
            }
        }

        if( type === 'message-account-manager' )
        {
            if( source === 'job-list' || source === 'job' || source === 'application-list' || source === 'application' )
            {
                return this.has(['employer','recruiter']);
            }
            else if( source === 'contract-list' || source === 'contract' || source === 'placement-list' || source === 'placement' )
            {
                return this.has(['employer','recruiter']) && data?.contacts?.accountManagers?.length;
            }
        }


        if( type === 'livechat-reply' )
        {
            if( source === 'global' )
            {
                return !data?.thread?.solved && data?.thread?.members?.find( m => m.account.id === this.store.state.auth.user.userDetail.currentAccount.id );
            }
        }

        if( type === 'livechat-join' )
        {
            if( source === 'global' )
            {
                return data?.thread?.members?.length === 1;
            }
        }

        if( type === 'livechat-reply-to-message' ) //TODO
        {
            if( source === 'global' )
            {
                return true; //!this.isRampAccount() && data?.thread?.members?.find( m => m.account.id === this.store.state.auth.user.userDetail.currentAccount.id ) && data?.sender?.id !== this.store.state.auth.user.userDetail.currentAccount.id;
            }
        }


        if( type === 'note-create' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount() && this.has(['admin','employer','recruiter']);
            }
        }

        if( type === 'note-progress' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount() && this.has(['admin','employer','recruiter']);
            }
        }

        if( type === 'note-delete' )
        {
            if( source === 'global' )
            {
                return !this.isRampAccount() && this.has(['admin','employer','recruiter']) && data.creator?.id === this.store.state.auth.user.userDetail.currentAccount.id;
            }
        }
    }
}