<template>
    <router-view v-slot="{ Component, route }">
        <transition name="fade" mode="out-in" appear>
            <div class="Main" v-if="isRouterLoaded">
                <component v-if="!$store.getters['auth/user/isLoggingIn']" :is="Component" />
                <SelectingAccount v-else />
            </div>
            <LoadingScreen v-else />
        </transition>
    </router-view>
</template>

<script setup>
import { ref, getCurrentInstance } from "vue";

import { sanitize, sanitizeURL, capitalize, toUTCDate } from "@/core/utils";

import LoadingScreen    from '@/templates/components/LoadingScreen';
import SelectingAccount from "@/templates/views/system/SelectingAccount.vue";

const app = getCurrentInstance();

const { $router } = app.appContext.config.globalProperties;

const isRouterLoaded = ref( false );

$router.isReady().then( () => setTimeout( () => isRouterLoaded.value = true, 500 ) );
</script>
